import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I can keep talking with you on topics that fascinate me; like science, philosophy and mathematics. I like sweets. A lot. I am open minded to the point of naivety. I have been working (unsuccessfully) on improving my handwriting for the past 8 years. You can message me and will find that I am an open book, ask any question without fear of offending me.`}</p>
    </blockquote>
    <p>{`Few of the things that I love to tell people about is the `}<a parentName="p" {...{
        "href": "https://distill.pub/2017/research-debt/"
      }}>{`Research Debt`}</a>{`, how awesome the `}<a parentName="p" {...{
        "href": "https://stackexchange.com/users/2408244/aditya"
      }}>{`stackexchange`}</a>{` sites are, how we need to talk about meaningful `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?list=EC7679C7ACE93A5638&v=s7tWHJfhiyo"
      }}>{`electoral reform`}</a>{`, how `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=HEfHFsfGXjs"
      }}>{`3b1b`}</a>{` is just at the God tier!! I am unashamedly a fanboy. `}</p>
    <p>{`We have to look beyond using just plain text and images to disseminate knowledge. I think `}<a parentName="p" {...{
        "href": "http://worrydream.com/ExplorableExplanations/"
      }}>{`explorable explanations`}</a>{` is the future. Some examples you can check out are `}<a parentName="p" {...{
        "href": "https://eater.net/quaternions"
      }}>{`quaternions`}</a>{`, `}<a parentName="p" {...{
        "href": "https://bost.ocks.org/mike/algorithms/"
      }}>{`algorithms`}</a>{`, and  `}<a parentName="p" {...{
        "href": "https://ncase.me/trust/"
      }}>{`Evolution of Trust`}</a>{`. Look just go find a topic you like `}<a parentName="p" {...{
        "href": "https://explorabl.es/"
      }}>{`here`}</a>{` and please spread the word!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      