import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "mailto:adityaprasad@iisc.ac.in"
      }}>{`Hi`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://t.me/everythingisrelative"
      }}>{`Telegram`}</a>{` & `}<a parentName="p" {...{
        "href": "https://i.imgur.com/WbLO6th.png"
      }}>{`Whatsapp`}</a>{`.`}</p>
    <p>{`You can find more projects I am working on by checking my `}<a parentName="p" {...{
        "href": "https://github.com/anantham/"
      }}>{`Github`}</a>{` profile out. `}</p>
    <p>{`I also publish blog posts on `}<a parentName="p" {...{
        "href": "https://blog.adityaarpitha.com/"
      }}>{`Ghost`}</a>{`, I'd love your thoughts on them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      